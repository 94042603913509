@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap");

.game {
  display: flex;
  flex-wrap: wrap;
  height: 560px;
  width: 560px;
}
.game img {
  height: 70px;
  width: 70px;
}
.footer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
