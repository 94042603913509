.days {
    display: grid;
    grid-template-columns: repeat(52, 1fr);
    /* box-shadow: 0 15px 35px 0 rgba(42, 51, 83, 0.12),
      0 5px 15px rgba(0, 0, 0, 0.06); */
  }

  .day {
    background-color: #EAF4F2;
  }
  
  .day--active {
    background-color: #291D89;
    
  }